<script>
export default {
  metaInfo() {
    return {
      title: 'Governance - About - Europe PMC',
    }
  },
}
</script>
<template>
  <div id="governance-page">
    <div id="governance-page-content" class="grid-row">
      <div class="col-11">
        <h1>Governance</h1>
        <p>
          Europe PMC is developed by the
          <a href="//www.ebi.ac.uk/">EMBL-EBI</a>
          (European Bioinformatics Institute), an outstation of the EMBL. A
          grant has been awarded by the Wellcome on behalf of the
          <a href="/Funders">Europe PMC Funders</a>
          to Dr Jo McEntyre to maintain and develop Europe PMC from 2016 to
          2021.
        </p>
        <p>
          Europe PMC has an international, independent Scientific Advisory Board
          that advises the PI of the Europe PMC grant and the Directors of the
          EMBL-EBI. The Europe PMC Funders are represented by the Funder
          Committee. These groups are instrumental to the strategic direction
          and governance of Europe PMC.
        </p>
        <h2>The Scientific Advisory Board</h2>
        <p>
          The EBI Literature Services Advisory Board meets annually and advises
          the EMBL-EBI on all aspects of the Literature Databases and Services,
          putting the development of these resources in the context of the
          scientific community within Europe and beyond. More specifically for
          Europe PMC, they review progress on the development of the service
          over the past year, and the plans for development for the forthcoming
          year. They advise the Europe PMC Funder Committee as to the overall
          effective use of funds from the Europe PMC grant. The Scientific
          Advisory Board members are:
        </p>
        <ul>
          <li>Jürg Bähler (Chair), University College London, UK</li>
          <li>Jonny Coates, ASAPbio, UK</li>
          <li>Veronique Kiermer, PLOS, USA</li>
          <li>Andiswa Mfengu, University of Cape Town, South Africa</li>
          <li>Alfonso Valencia, Barcelona Supercomputing Centre, Spain</li>
          <li>Gemma Villanueva, The Cochrane Collaboration, Oxford, UK</li>
        </ul>
        <h2>The Funder Committee</h2>
        <p>
          The Funder Committee meets twice a year to review completed
          development, comment on future development and approve the release of
          funds on behalf of the Funders’ Group.
        </p>
        <ul>
          <li>Geraldine Clement-Stoneham, MRC (Chair)</li>
          <li>Andrew Knowles, CRUK</li>
          <li>Tobias Philipp, SNSF</li>
          <li>Hannah Hope, Wellcome</li>
          <li>Ian Coltart, WHO</li>
          <li>Inesa Thomsen, DHSC</li>
          <li>Matthew Lam, Worldwide Cancer Research</li>
          <li>Bela Desai, Wellcome Trust/DBT India Alliance</li>
          <li>Chris Macdonald, Pancreatic Cancer UK</li>
          <li>Dagmar Meyer, ERC (non-voting)</li>
        </ul>
        <div>
          <a href="/doc/Funders_TOR.pdf">Funder Committee Terms of Reference</a>
        </div>
        <h2>The Funders' Group</h2>
        <p>
          The Funders' Group consists of representation from all
          <a href="/Funders">Europe PMC Funders</a> and meets annually.
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#governance-page-content {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
}
</style>
